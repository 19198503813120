import { IMG_4, LOGO, LOGO_BIGGER } from "../constants/img";
import { Fade } from "react-reveal";
import { Link, useNavigate } from "react-router-dom";
import HeroSectionCards from "../components/home/hero-section/Cards";
import ServicesSectionCards from "../components/home/serices-section/Cards";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import InquirySection from "../components/InquirySection";
import Header from "../components/Header";
import "../Home.css";
import { useEffect } from "react";
import { services_cards } from "../data";
import ParticlesBg from "../components/ParticlesBg";
import { IMG_1, IMG_2, IMG_3 } from "../constants/img";
export default function Home() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="home">
      <ParticlesBg />
      <div className="header-container">
        <div className="header-details">
          <Header />
        </div>
      </div>
      <section className="hero-section section">
        <div className="mx-auto  details">
          <div className="home-details mb-4">
            <div className="info">
              <Fade ssrFadeout={true} duration={1500} delay={1000} bottom>
                <h1 className="title">We help brands on a global scale.</h1>
              </Fade>
              <Fade ssrFadeout={true} duration={1500} delay={1500} bottom>
                <h1 className="subtitle">We are Emmera SoftTech</h1>
              </Fade>
              <Fade ssrFadeout={true} duration={1500} delay={2000} bottom>
                <p className="text">
                  Turning rough ideas into the inspired brands, giving a proper
                  kickstart to the startups, and reimagine existing brands for
                  the future - Emmera Softtech is a small but perfect team that
                  works with people on a global scale who are passionate about
                  who they are.
                </p>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      {/* services */}
      <section className="services-section section">
        {/* hero-section three cards */}
        <div className="details">
          <div className="details-parent">
            <Fade ssrFadeout={true} duration={1000} delay={0} left>
              <div className="title">
                <Fade ssrFadeout={true} duration={700} delay={100} right>
                  <span className="horizontal-bar"></span>
                </Fade>
                <h1>Services.</h1>
              </div>
            </Fade>
            <Fade ssrFadeout={true} duration={1000} delay={200} left>
              <p className="subtitle">our services for clients</p>
            </Fade>
          </div>
        </div>
        <ServicesSectionCards cards={services_cards} />
        <Fade ssrFadeout={true} duration={700} delay={100} bottom>
          <div className="vertical-bar"></div>
        </Fade>
      </section>
      {/* works-section */}
      <section className="works-section section">
        <div className="details">
          <div>
            <Fade ssrFadeout={true} duration={1000} delay={0} left>
              <div className="title">
                <Fade ssrFadeout={true} duration={700} delay={100} right>
                  <span className="horizontal-bar"></span>
                </Fade>
                <h1>Works.</h1>
              </div>
            </Fade>
            <Fade ssrFadeout={true} duration={1000} delay={200} left>
              <p className="subtitle">Things we've made</p>
            </Fade>
          </div>
          <div className="work-btn">
            <Link to="/works" className="btn">
              view all
            </Link>
          </div>
        </div>
        <div className="works-imgs">
          <div className="row-one">
            <img
              src={IMG_1}
              alt="one"
              onClick={() => {
                navigate("/work?s=1");
              }}
            />
          </div>
          <div className="row-two">
            <div className="img">
              <img
                src={IMG_2}
                alt="two"
                onClick={() => {
                  navigate("/work?s=2");
                }}
              />
            </div>
            <div className="img">
              <img
                src={IMG_3}
                alt="three"
                onClick={() => {
                  navigate("/work?s=3");
                }}
              />
            </div>
          </div>
        </div>
        <Fade ssrFadeout={true} duration={700} delay={100} bottom>
          <div className="vertical-bar"></div>
        </Fade>
      </section>
      <section className="about-section section">
        <div className="details">
          <div className="details-parent">
            <Fade ssrFadeout={true} duration={1000} delay={0} left>
              <div className="title">
                <Fade ssrFadeout={true} duration={700} delay={100} right>
                  <span className="horizontal-bar"></span>
                </Fade>
                <h1>About</h1>
              </div>
            </Fade>
          </div>
          <Fade ssrFadeout={true} duration={1500} delay={200} bottom>
            <div className="info">
              <div className="box">
                <h2 className="title">Who we are</h2>
                <p className="text">
                  The expert team with friendly behaviour who is always
                  motivated to work and give best solution with their skills.
                </p>
              </div>
              <div className="box">
                <h2 className="title">Our philosophy</h2>
                <p className="text">
                  Our vision is to give best solution to our clients with our
                  100% commitment and great skills. We are solution oriented
                  with never-give up mindset.
                </p>
              </div>
              {/* <Fade ssrFadeout={true} duration={1500} delay={300} bottom>
                <div className="box">
                  <h2 className="title">How we work</h2>
                  <p className="text">
                    We have two modes of work one is fixed requirement and
                    second is agile development.
                  </p>
                </div>
              </Fade> */}
            </div>
          </Fade>
        </div>
      </section>
      {/* inquiry-section */}
      <InquirySection />
      {/* contact-section */}
      <ContactSection />
      {/* footer */}
      <Footer />
    </div>
  );
}
