import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import ServicesSectionCards from "../components/home/serices-section/Cards";
import InquirySection from "../components/InquirySection";
import Header from "../components/Header";
import "../Services.css";
import { useEffect } from "react";
import { services_cards } from "../data";
import ParticlesBg from "../components/ParticlesBg";
export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="services-container">
      <ParticlesBg />
      <div className="header-container">
        <div className="header-details">
          <Header />
        </div>
      </div>
      <div className="service-details">
        {/* <ParticlesBg /> */}
        <div className="service-info">
          <div className="intro">
            <h1 className="title">Our Services.</h1>
          </div>
          {/* <div className="info">
            <Fade ssrFadeout={true} duration={1000} delay={400} bottom>
              <h2 className="title">Our Services.</h2>
            </Fade>
            <Fade ssrFadeout={true} duration={800} delay={600} bottom>
              <p className="text">Our Services for clients</p>
            </Fade>
          </div> */}
        </div>
      </div>
      <section className="services-section section">
        <ServicesSectionCards cards={services_cards} />
        <Fade ssrFadeout={true} duration={700} delay={100} bottom>
          <div className="vertical-bar"></div>
        </Fade>
      </section>
      <section className="projects-links-section">
        <div className="details">
          <Fade ssrFadeout={true} duration={700} delay={500} top>
            <h1 className="title">Wanna see our works?</h1>
          </Fade>
          <Fade ssrFadeout={true} duration={700} delay={100} bottom>
            <div className="projects-btn">
              <Link to="/works" className="btn">
                all projects
              </Link>
            </div>
          </Fade>
        </div>
      </section>
      <section className="skills-section section">
        <div className="details">
          <div className="info">
            <h1 className="title">Here are some of our great skills</h1>
            <p className="text">
              We are expert in flutter development. Using flutter we can create
              both ios and android apps with great performance , unique features
              and appealing UI/UX.
            </p>
          </div>
          <div className="list">
            <ul>
              <li>App Development</li>
              <li>Web Development</li>
            </ul>
          </div>
        </div>
      </section>
      <InquirySection />
      <ContactSection />
      <Footer />
    </div>
  );
}
