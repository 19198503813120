import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
export default function InquirySection() {
  return (
    <section className="inquiry-section">
      <Fade ssrFadeout={true} duration={700} delay={500} bottom>
        <div className="vertical-bar"></div>
      </Fade>
      <div className="details">
        <Fade ssrFadeout={true} duration={700} delay={500} top>
          <h1 className="title">Have any project in mind?</h1>
        </Fade>
        <Fade ssrFadeout={true} duration={700} delay={100} bottom>
          <div className="inquiry-btn">
            <Link to="/contact" className="btn">
              Make Inquiry
            </Link>
          </div>
        </Fade>
      </div>
    </section>
  );
}
