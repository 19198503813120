export default function Footer() {
  return (
    <footer className="footer-section section">
      <div className="footer-info">
        <p className="footer-title">Copyright 2022 All rights reserved</p>
        {/* <p className="footer-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
        </p> */}
      </div>
    </footer>
  );
}
