import { LOGO, LOGO_BIGGER } from "../constants/img";
import { CgMenuGridR, CgClose } from "react-icons/cg";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Fade } from "react-reveal";
import ParticlesBg from "./ParticlesBg";
export default function Header() {
  const [show, setShow] = useState(false);
  const { pathname } = useLocation();
  return (
    <Fade ssrFadeout={true} duration={2000} top>
      <div className={`header-modal ${show ? "show-modal" : "hide-modal"}`}>
        <span className="close-icon">
          <CgClose
            className="text-white text-4xl mb-4"
            onClick={() => setShow(!show)}
          />
        </span>
        <ul className="h-full m-auto">
          <Link to="/">
            <li
              className={`${pathname === "/" ? "horizontal-bar" : ""}`}
              onClick={() => {
                setShow(false);
              }}
            >
              Home
            </li>
          </Link>
          <Link to="/services">
            <li
              className={`${pathname === "/services" ? "horizontal-bar" : ""}`}
              onClick={() => {
                setShow(false);
              }}
            >
              Services
            </li>
          </Link>
          <Link to="/works">
            <li
              className={`${pathname === "/works" ? "horizontal-bar" : ""}`}
              onClick={() => {
                setShow(false);
              }}
            >
              Works
            </li>
          </Link>
          {/* <Link to="/about">
            <li
              className={`${pathname === "/about" ? "horizontal-bar" : ""}`}
              onClick={() => {
                setShow(false);
              }}
            >
              About
            </li>
          </Link> */}
          <Link to="/contact">
            <li
              className={`${pathname === "/contact" ? "horizontal-bar" : ""}`}
              onClick={() => {
                setShow(false);
              }}
            >
              Contact us
            </li>
          </Link>
        </ul>
      </div>
      <nav className="h-24  flex items-center justify-between flex-wrap header">
        <div className="logo flex items-center flex-shrink-0">
          {/* <img src={LOGO_BIGGER} alt="logo" /> */}
          <p>Emmera SoftTech</p>
        </div>

        <div className="h-full lg:block hidden">
          <ul className="h-full flex items-center justify-center">
            <Link to="/">
              <li className={`${pathname === "/" ? "horizontal-bar" : ""}`}>
                Home
              </li>
            </Link>
            <Link to="/services">
              <li
                className={`${
                  pathname === "/services" ? "horizontal-bar" : ""
                }`}
              >
                Services
              </li>
            </Link>
            <Link to="/works">
              <li
                className={`${pathname === "/works" ? "horizontal-bar" : ""}`}
              >
                Works
              </li>
            </Link>
            {/* <Link to="/about">
              <li
                className={`${pathname === "/about" ? "horizontal-bar" : ""}`}
              >
                About
              </li>
            </Link> */}
            <Link to="/contact">
              <li
                className={`${pathname === "/contact" ? "horizontal-bar" : ""}`}
              >
                Contact us
              </li>
            </Link>
          </ul>
        </div>
        <div className="icon menu-icon lg:hidden">
          <CgMenuGridR
            className="text-white text-5xl mb-4"
            onClick={() => setShow(!show)}
          />
        </div>
      </nav>
    </Fade>
  );
}
