export const LOGO = "/assets/imgs/web-logo-1.png";
export const LOGO_BIGGER = "/assets/imgs/FINAL_LOGO.png";

// works-images path

// 1. badminton

export const BADMINTON_BANNER = "/assets/imgs/works/badminton/badminton.jpg";

export const BADMINTON = [
  {
    title: "first",
    link: "/assets/imgs/works/badminton/badminton.jpg",
  },
  {
    title: "second",
    link: "/assets/imgs/works/badminton/briflyD.jpg",
  },
  {
    title: "third",
    link: "/assets/imgs/works/badminton/briflyD2.jpg",
  },
  {
    title: "fourth",
    link: "/assets/imgs/works/badminton/briflyL.jpg",
  },
  {
    title: "five",
    link: "/assets/imgs/works/badminton/briflyl2.jpg",
  },
  {
    title: "BADMINTON_IMG_6",
    link: "/assets/imgs/works/badminton/badminton-img-u-3.png",
  },
];

export const IMG_1 = "/assets/imgs/works/badminton/img_1.jpeg";
export const IMG_2 = "/assets/imgs/2.jpg";
export const IMG_3 = "/assets/imgs/3.jpg";
export const IMG_4 = "/assets/imgs/4.jpg";
export const IMG_5 = "/assets/imgs/5.jpg";

export const BIG_IMG = "/assets/imgs/BIG_IMG.jpg";
export const PLAYSTORE = "/assets/imgs/playstore.png";
export const APPSTORE = "/assets/imgs/appstore.png";

//  big single image data (link,title)

export const BIG_IMG_DATA = [
  {
    id: "1",
    link: "/assets/imgs/BIG_IMG_1.png",
    title: "Mooncase",
    android: "https://play.google.com/store/apps/details?id=io.mooncase.invest",
    ios: "",
  },
  {
    id: "2",
    link: "/assets/imgs/BIG_IMG_2.png",
    title: "Badminton Court Booking",
    android: "https://play.google.com/store/apps/details?id=com.vin.sports",
    ios: "",
  },
  {
    id: "3",
    link: "/assets/imgs/BIG_IMG_3.png",
    title: "MVP Event Staffing",
    android:
      "https://play.google.com/store/apps/details?id=com.kptech80.mvpeventstaffing",
    ios: "https://apps.apple.com/us/app/mvp-event-staffing/id1591881518#?platform=iphone",
  },
  {
    id: "4",
    link: "/assets/imgs/BIG_IMG_4.png",
    title: "Brifly News",
    android:
      "https://play.google.com/store/apps/details?id=com.brifly.brifly_app",
    ios: "https://apps.apple.com/in/app/Brifly/id1578645312",
  },
  {
    id: "5",
    link: "/assets/imgs/BIG_IMG_5.png",
    title: "Suriwallet",
    android: "",
    ios: "",
  },
];
