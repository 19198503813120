import { Fade, Roll } from "react-reveal";
import { Link } from "react-router-dom";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import InquirySection from "../components/InquirySection";
import {
  IoCallOutline,
  IoMailOutline,
  IoLocationOutline,
} from "react-icons/io5";
import QueryForm from "../components/QueryForm";
import Header from "../components/Header";
import "../Contact.css";
import { useEffect } from "react";
import ParticlesBg from "../components/ParticlesBg";
export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contactus-container">
      <ParticlesBg />
      <div className="header-container">
        <div className="header-details">
          <Header />
        </div>
      </div>
      <div className="contactus-details">
        {/* <ParticlesBg /> */}
        <div className="contactus-info">
          <div className="intro">
            <h1 className="title">Contact Us</h1>
            <h2 className="sub-title">hello@emmerasofttech.com</h2>
          </div>
          {/* <div className="info">
            <Fade ssrFadeout={true} duration={1000} delay={400} bottom>
              <h2 className="title">Get in touch with us!</h2>
            </Fade>
            <Fade ssrFadeout={true} duration={800} delay={600} bottom>
            </Fade>
          </div> */}
        </div>
      </div>
      {/* <section className="contactusdetails-section section">
        <div className="info">
          <Roll ssrFadeout={true} duration={1000} delay={200} left>
            <div className="box">
              <h1 className="icon">
                <IoCallOutline />
              </h1>
              <h2 className="title">Phone</h2>
              <p className="text">
                <span>+919510731828</span>
                <span>+919824390121</span>
              </p>
            </div>
          </Roll>
          <Roll ssrFadeout={true} duration={1000} delay={100} bottom>
            <div className="box">
              <h1 className="icon">
                <IoLocationOutline />
              </h1>
              <h2 className="title">Address</h2>
              <p className="text">
                <span>
                  409, Pride square, <br />
                  Pushkardham main road, <br />
                  near Jk chowk, <br />
                  Rajkot - 360005
                </span>
              </p>
            </div>
          </Roll>
          <Roll ssrFadeout={true} duration={1000} delay={300} right>
            <div className="box">
              <h1 className="icon">
                <IoMailOutline />
              </h1>
              <h2 className="title">email</h2>
              <p className="text">
                <span>hello@asquaretechlab.com</span>
              </p>
            </div>
          </Roll>
        </div>
      </section> */}
      <div className="query">
        <section className="contactus-query section">
          <QueryForm />
        </section>
      </div>
      {/* <InquirySection /> */}
      <ContactSection />
      <Footer />
    </div>
  );
}
