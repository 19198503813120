import { Fade } from "react-reveal";
// import { Link } from "react-router-dom";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import AboutSectionCards from "../components/home/about-section/Cards";
import InquirySection from "../components/InquirySection";
import Header from "../components/Header";
import "../About.css";
import { useEffect } from "react";
import ParticlesBg from "../components/ParticlesBg";
export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="about-container">
      <ParticlesBg />
      <div className="header-container">
        <div className="header-details">
          <Header />
        </div>
      </div>
      <div className="about-details">
        {/* <ParticlesBg /> */}
        <div className="about-info">
          <div className="intro">
            <h1 className="title">About us.</h1>
          </div>
        </div>
      </div>
      <section className="about-section section">
        <div className="details">
          <Fade ssrFadeout={true} duration={1500} delay={200} bottom>
            <div className="info">
              <div className="box">
                <h2 className="title">Who we are</h2>
                <p className="text">
                  The expert team with friendly behaviour who is always
                  motivated to work and give best solution with their skills.
                </p>
              </div>
              <div className="box">
                <h2 className="title">Our philosophy</h2>
                <p className="text">
                  Our vision is to give best solution to our clients with our
                  100% commitment and great skills. We are solution oriented
                  with never-give up mindset.
                </p>
              </div>
              {/* <Fade ssrFadeout={true} duration={1500} delay={300} bottom>
                <div className="box">
                  <h2 className="title">How we work</h2>
                  <p className="text">
                    We have two modes of work one is fixed requirement and
                    second is agile development.
                  </p>
                </div>
              </Fade> */}
            </div>
          </Fade>
        </div>
      </section>
      <section className="services-section section">
        {/* <AboutSectionCards /> */}
        {/* <Fade ssrFadeout={true} duration={700} delay={100} bottom>
          <div className="vertical-bar"></div>
        </Fade> */}
      </section>
      <section className="skills-section section">
        <div className="skills-part skills-part-one">
          <div className="details">
            <Fade ssrFadeout={true} duration={1000} delay={100} left>
              <div className="thumbnail"></div>
            </Fade>
            <Fade ssrFadeout={true} duration={1000} delay={100} right>
              <div className="info">
                <h1 className="title">Here are some of our great skills</h1>
                <p className="text">
                  We are expert in flutter development. Using flutter we can
                  create both ios and android apps with great performance ,
                  unique features and appealing UI/UX.
                </p>
                <div className="list">
                  <ul>
                    <Fade ssrFadeout={true} duration={1000} delay={100} right>
                      <li className="skill">App Development</li>
                    </Fade>
                    <Fade ssrFadeout={true} duration={1000} delay={200} right>
                      <li className="skill">Web Development</li>
                    </Fade>
                  </ul>
                </div>
              </div>
            </Fade>
          </div>
        </div>
        {/* <div className="skills-part skills-part-two">
          <div className="details">
            <Fade ssrFadeout={true} duration={1000} delay={100} right>
              <div className="thumbnail"></div>
            </Fade>
            <Fade ssrFadeout={true} duration={1000} delay={100} left>
              <div className="info">
                <h1 className="title">Best Solutions for Your Business</h1>
                <p className="text">
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Assumenda, soluta excepturi doloremque facilis minima iusto
                  dolorem earum architecto perferendis et fuga sequi? Quo
                  sapiente vel aperiam facilis iusto sint non, quae unde
                  temporibus nihil quia provident? Dicta quidem illo totam?
                </p>
              </div>
            </Fade>
          </div>
        </div> */}
      </section>
      <InquirySection />
      <ContactSection />
      <Footer />
    </div>
  );
}
