import "./QueryForm.css";
import emailjs from "@emailjs/browser";
import { useRef } from "react";

export default function QueryForm() {
  const formRef = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_lffucd6",
        "template_jtlxy7k",
        formRef.current,
        "RQZ1Ewu7HqwV_dc2n"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            alert("your query is submitted!");
          }
        },
        (error) => {
          console.log(error.text);
          alert(error.text);
        }
      );
  };
  return (
    <section className="queryform-section">
      <h1 className="title">Have any project in mind?</h1>
      {/* <p className="subtitle">Lorem ipsum dolor sit amet.</p> */}
      <div className="form">
        <form ref={formRef} onSubmit={sendEmail}>
          <div className="element-row">
            <input
              type="text"
              placeholder="First Name"
              className="inputfield"
              name="user_firstname"
              required={true}
            />
            <input
              type="text"
              placeholder="Last Name"
              className="inputfield"
              name="user_lastname"
              required={true}
            />
          </div>
          <div className="element-row">
            <input
              type="text"
              placeholder="Email"
              className="inputfield"
              name="user_email"
              required={true}
            />
            <input
              type="text"
              placeholder="Subject"
              className="inputfield"
              name="email_subject"
              required={true}
            />
          </div>
          <div className="element-row">
            <textarea
              placeholder="Your Message"
              cols="30"
              rows="10"
              className="textfield"
              required={true}
              name="message"
            ></textarea>
          </div>
          <div className="submit-btn">
            <button className="btn">send message</button>
          </div>
        </form>
      </div>
    </section>
  );
}
