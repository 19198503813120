import Header from "../components/Header";
import Image from "../components/works/Image";
import "../Works.css";
import { Fade } from "react-reveal";
import {
  BADMINTON_BANNER,
  BADMINTON,
  IMG_1,
  IMG_2,
  IMG_3,
  IMG_4,
  IMG_5,
} from "../constants/img";
import InquirySection from "../components/InquirySection";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import { useEffect } from "react";
import ParticlesBg from "../components/ParticlesBg";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
export default function Works() {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [Banner, SetBanner] = useState(BADMINTON_BANNER);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (search !== null || search !== "") {
      let term = search.substring(3);
      const [img] = BADMINTON.filter((item) => item.title === term);
      if (img !== undefined) {
        SetBanner(img.link);
      }
    }
  }, [search]);

  return (
    <div className="works-container">
      <ParticlesBg />
      <div className="header-container">
        <div className="header-details">
          <Header />
        </div>
      </div>
      <div className="works-details">
        {/* <ParticlesBg /> */}
        <div className="works-info">
          <div className="intro">
            <h1 className="title">Our Works.</h1>
          </div>
          {/* <div className="info">
            <Fade ssrFadeout={true} duration={1000} delay={400} bottom>
              <h2 className="title">Our Works</h2>
            </Fade>
            <Fade ssrFadeout={true} duration={800} delay={600} bottom>
              <p className="text">SEE ALL OUR WORKS</p>
            </Fade>
          </div> */}
        </div>
      </div>
      <section className="works-section section">
        {/* <div className="work-imgs">
          <Image banner={Banner} imgs={BADMINTON} />
        </div> */}
        <div className="works-imgs">
          <div className="row-one">
            <img
              src={IMG_1}
              alt="one"
              onClick={() => {
                navigate("/work?s=1");
              }}
            />
          </div>
          <div className="row-two">
            <div className="img">
              <img
                src={IMG_2}
                alt="two"
                onClick={() => {
                  navigate("/work?s=2");
                }}
              />
            </div>
            <div className="img">
              <img
                src={IMG_3}
                alt="three"
                onClick={() => {
                  navigate("/work?s=3");
                }}
              />
            </div>
          </div>
          <div className="row-two row-three">
            <div className="img">
              <img
                src={IMG_4}
                alt="two"
                onClick={() => {
                  navigate("/work?s=4");
                }}
              />
            </div>
            {/* <div className="img">
              <img
                src={IMG_5}
                alt="three"
                onClick={() => {
                  navigate("/work?s=5");
                }}
              />
            </div> */}
          </div>
        </div>
      </section>
      <InquirySection />
      <ContactSection />
      <Footer />
    </div>
  );
}
