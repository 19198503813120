import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Link, useLocation } from "react-router-dom";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ParticlesBg from "../components/ParticlesBg";
import { BIG_IMG, BIG_IMG_DATA, APPSTORE, PLAYSTORE } from "../constants/img";
import { FaAndroid, FaApple } from "react-icons/fa";
import "../Work.css";
import { useRef } from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function Work() {
  const { search } = useLocation();
  const [banner, setBanner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [androidLink, setAndroidLink] = useState("");
  const [iosLink, setIosLink] = useState("");
  const [singleImg, setSingleImg] = useState(true);
  const counter = useRef(0);
  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current > 0) {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    if (search !== null || search !== "") {
      let term = search.substring(3);
      const [img] = BIG_IMG_DATA.filter((item) => item.id === term);
      if (img !== undefined) {
        setBanner(img.link);
        setTitle(img.title);
        setAndroidLink(img.android);
        setIosLink(img.ios);
        if (img.android !== "" && img.ios !== "") {
          setSingleImg(false);
        }
      }
    }
  }, [search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="work-container">
      <ParticlesBg />
      <div className="header-container">
        <div className="header-details">
          <Header />
        </div>
      </div>
      <div className="work-details section">
        {/* <ParticlesBg /> */}
        <div className="work-info">
          <div className="intro">
            <Fade ssrFadeout={true} duration={1500} delay={500} bottom>
              <h1 className="title">{title}</h1>
            </Fade>
          </div>
        </div>
      </div>
      <section className="single-work section">
        <div className="work-img">
          <div
            className="loader"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "6rem",
            }}
          >
            {loading && (
              <Stack spacing={2} direction="row">
                <CircularProgress
                  color="success"
                  size={"4rem"}
                  style={{ color: "#66fcf1" }}
                />
              </Stack>
            )}
          </div>
          <img
            style={{ visibility: loading ? "hidden" : "visible" }}
            src={banner}
            alt="work"
            onLoad={imageLoaded}
          />
          {/* } */}
          <ul
            className={`${singleImg ? "app-links single-img" : "app-links"}`}
            style={{ visibility: loading ? "hidden" : "visible" }}
          >
            {androidLink !== "" && (
              <li className="link android">
                <a href={androidLink} target="_blank">
                  <img src={PLAYSTORE} alt="playstore" />
                </a>
              </li>
            )}
            {iosLink !== "" && (
              <li className="link apple">
                <a href={iosLink} target="_blank">
                  <img src={APPSTORE} alt="appstore" />
                </a>
              </li>
            )}
          </ul>
        </div>
      </section>
      <ContactSection />
      <Footer />
    </div>
  );
}
