import { LOGO, LOGO_BIGGER } from "../constants/img";
import {
  IoCallOutline,
  IoMailOutline,
  IoCalendarOutline,
  IoLocationOutline,
} from "react-icons/io5";
import { BsLinkedin, BsFacebook, BsTwitter, BsInstagram } from "react-icons/bs";
import "./ContactSection.css";
const ContactSection = () => {
  return (
    <section className="contact-section section">
      <div className="company-details">
        <div className="logo">
          <p>Emmera SoftTech</p>
          {/* <img src={LOGO_BIGGER} alt="LOGO" /> */}
          {/* <div className="horizontal-bar"></div> */}
        </div>
        {/* <ul className="social-links">
          <li className="link">
            <a href="https://www.linkedin.com/company/asquare-tech-lab/">
              <BsLinkedin />
            </a>
          </li>
          <li className="link">
            <a href="https://www.facebook.com/asquare29">
              <BsFacebook />
            </a>
          </li>
          <li className="link">
            <a href="https://twitter.com/AsquareTechLab">
              <BsTwitter />
            </a>
          </li>
          <li className="link">
            <a href="https://www.instagram.com/asquaretechlab/">
              <BsInstagram />
            </a>
          </li>
        </ul> */}
        {/* <div className="contact-info">
          <h3 className="contact-title">contact us</h3>
          <ul>
            <li className="contact-item">
              <span className="icon">
                <IoCallOutline />
              </span>
              <div className="contact-numbers">
                <p className="text">+919510731828</p>
                <p className="text">+919824390121</p>
              </div>
            </li>
            <li className="contact-item">
              <span className="icon">
                <IoMailOutline />
              </span>
              <span className="text">hello@asquaretechlab.com</span>
            </li>
            <li className="contact-item">
              <span className="icon">
                <IoCalendarOutline />
              </span>
              <span className="text">Monday - Friday</span>
            </li>
            <li className="contact-item">
              <span className="icon">
                <IoLocationOutline />
              </span>
              <span className="text">
                409, Pride square, Pushkardham main road, near Jk chowk, Rajkot
                - 360005
              </span>
            </li>
          </ul>
        </div> */}
      </div>
    </section>
  );
};

export default ContactSection;
