import { FaEquals, FaPlus } from "react-icons/fa";
const services_card_one = {
  title: "App Development",
  icons: [
    { title: "img", value: "/assets/imgs/flutter_logo.svg", cls: "flutter" },
    { title: "icon", value: <FaEquals />, cls: "equals" },
    { title: "img", value: "/assets/imgs/android.png", cls: "android" },
    { title: "icon", value: <FaPlus />, cls: "plus" },
    { title: "img", value: "/assets/imgs/ios.png", cls: "ios" },
  ],
  info: "We will develop a world class app for you according to your visions using Flutter. Unique features, appealing UI/UX and best performance can help your business in different segments to grab never-ending opportunities.",
  details:
    "Flutter app development has come a long way since its inception both in terms of framework maturity and use cases.",
};

const services_card_two = {
  title: "Web Development",
  icons: [
    { title: "img", value: "/assets/imgs/react-js.svg", cls: "reactjs" },
    { title: "img", value: "/assets/imgs/flutter_logo.svg", cls: "flutter" },
  ],
  info: "Web applications are now very essential for any business, we will create extraordinary web apps for you using Flutter and ReactJs.",
  details:
    "Web applications are now very essential for any business, we will create extraordinary web apps for you using Flutter and ReactJs.",
};

export const services_cards = { services_card_one, services_card_two };
