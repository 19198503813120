import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
export default function Card({
  icon,
  title,
  desc,
  cls,
  animdelay,
  animduration,
  data,
}) {
  return (
    <Fade ssrFadeout={true} duration={0} delay={animdelay} left>
      <div className={`services-section-card ${cls}`}>
        <div className="icons">
          {data.icons.map((item, index) => {
            if (item.title === "img") {
              return (
                <div key={index} className={`img-icon ${item.cls}`}>
                  <img src={item.value} alt="icon" />
                </div>
              );
            } else if (item.title === "icon") {
              return (
                <div key={index} className={`icon ${item.cls}`}>
                  {item.value}
                </div>
              );
            }
          })}
        </div>
        <div className="title">{data.title}</div>
        {/* <div className="divider"></div> */}
        <div className="desc">
          <p className="first-para">{data.info}</p>
          <p className="second-para">{data.details}</p>
        </div>
        <div className="read-more-btn">
          <Link to="/services" className="btn">
            Read More
          </Link>
        </div>
      </div>
    </Fade>
  );
}
