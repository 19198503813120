import Card from "./Card";
import { FaTelegramPlane } from "react-icons/fa";
export default function ServicesSectionCards({ cards }) {
  return (
    <div className="cards">
      <Card
        icon={<FaTelegramPlane />}
        title={"App Development"}
        desc={""}
        animdelay={0}
        animduration={0}
        data={cards.services_card_one}
      />
      <Card
        icon={<FaTelegramPlane />}
        title={"Web Development"}
        desc={""}
        cls="highlight"
        animdelay={300}
        animduration={500}
        data={cards.services_card_two}
      />
    </div>
  );
}
